import * as types from "./constants";

export const setUser = (user) => ({
  type: types.SET_USER,
  payload: user,
});

export const setToken = (token) => ({
  type: types.SET_TOKEN,
  payload: token,
});

export const sessionExpired = (token) => ({
  type: types.SESSION_EXPIRED,
  payload: token,
});

export const networkError = (token) => ({
  type: types.NETWORK_ERROR,
  payload: token,
});

export const enqueueSnackbar = (notification) => {
  console.log(notification, "nonew");
  return {
    type: types.ENQUEUE_SNACKBAR,
    payload: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
  };
};

export const removeSnackbar = (payload) => ({
  type: types.REMOVE_SNACKBAR,
  payload,
});

export const logoutRequest = (payload) => ({
  type: types.LOGOUT_REQUEST,
  payload,
});

export const logoutSuccess = (payload) => ({
  type: types.LOGOUT_SUCCESS,
  payload,
});

export const logoutFailure = (payload) => ({
  type: types.LOGOUT_FAILURE,
  payload,
});

export const loadSlideRequest = (payload) => ({
  type: types.LOAD_SLIDE_REQUEST,
  payload,
});
export const loadSlideSuccess = (payload) => ({
  type: types.LOAD_SLIDE_SUCCESS,
  payload,
});
export const loadSlideFailure = (payload) => ({
  type: types.LOAD_SLIDE_FAILURE,
  payload,
});

export const loadMenuRequest = (payload) => ({
  type: types.LOAD_MENU_REQUEST,
  payload,
});
export const loadMenuSuccess = (payload) => ({
  type: types.LOAD_MENU_SUCCESS,
  payload,
});
export const loadMenuFailure = (payload) => ({
  type: types.LOAD_MENU_FAILURE,
  payload,
});

export const setCartId = (token) => ({
  type: types.SET_CART_ID,
  payload: token,
});

export const setGuestToken = (token) => ({
  type: types.SET_GUEST_TOKEN,
  payload: token,
});

export const loadContentRequest = (payload) => ({
  type: types.LOAD_CONTENT_REQUEST,
  payload,
});
export const loadContentSuccess = (payload) => ({
  type: types.LOAD_CONTENT_SUCCESS,
  payload,
});
export const loadContentFailure = (payload) => ({
  type: types.LOAD_CONTENT_FAILURE,
  payload,
});

export const loadCategoryRequest = (payload) => ({
  type: types.LOAD_CATEGORY_REQUEST,
  payload,
});
export const loadCategorySuccess = (payload) => ({
  type: types.LOAD_CATEGORY_SUCCESS,
  payload,
});
export const loadCategoryFailure = (payload) => ({
  type: types.LOAD_CATEGORY_FAILURE,
  payload,
});
