/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
// import { connectRouter } from "connected-react-router";
import { createReduxHistoryContext } from "redux-first-history";
import history from "./utils/history";
// import languageProviderReducer from 'containers/LanguageProvider/reducer';
import global from "./containers/App/reducer";
const { routerReducer } = createReduxHistoryContext(history);

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    // language: languageProviderReducer,
    router: routerReducer,
    global,
    ...injectedReducers,
  });

  return rootReducer;
}
