/*
 *
 * Media constants
 *
 */

export const LOAD_ALL_REQUEST = "app/Media/LOAD_ALL_REQUEST";
export const LOAD_ALL_SUCCESS = "app/Media/LOAD_ALL_SUCCESS";
export const LOAD_ALL_FAILURE = "app/Media/LOAD_ALL_FAILURE";

export const LOAD_ONE_REQUEST = "app/Media/LOAD_ONE_REQUEST";
export const LOAD_ONE_SUCCESS = "app/Media/LOAD_ONE_SUCCESS";
export const LOAD_ONE_FAILURE = "app/Media/LOAD_ONE_FAILURE";

export const ADD_MEDIA_REQUEST = "app/Media/ADD_MEDIA_REQUEST";
export const ADD_MEDIA_SUCCESS = "app/Media/ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_FAILURE = "app/Media/ADD_MEDIA_FAILURE";

export const DELETE_ONE_REQUEST = "app/Media/DELETE_ONE_REQUEST";
export const DELETE_ONE_SUCCESS = "app/Media/DELETE_ONE_SUCCESS";
export const DELETE_ONE_FAILURE = "app/Media/DELETE_ONE_FAILURE";

export const SET_ONE_VALUE = "app/Media/SET_ONE_VALUE";
export const CLEAR_ONE = "app/Media/CLEAR_ONE";

export const SET_QUERY_VALUE = "app/Media/SET_QUERY_VALUE";
export const CLEAR_QUERY = "app/Media/CLEAR_QUERY";
