/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';

// Import all the third party stuff
import React from "react";
// import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import { ConnectedRouter } from "connected-react-router";
// import { Router } from "react-router-dom";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { jwtDecode } from "jwt-decode";
import { SnackbarProvider } from "notistack";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
import history from "./utils/history";
// Import Language Provider
import LanguageProvider from "./containers/LanguageProvider";
// Import root app
import Apps from "./containers/App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider, LoginButton } from "react-facebook";

import configureStore from "./configureStore";
import { setUser, setToken } from "./containers/App/actions";
import { ThemeProvider } from "./Themecontext";
import "./styles.css";
// import { FB_APP_ID, FB_APP_FIELDS, GOOGLE_CLIENT_ID } from "../App/constants";
import {
  GOOGLE_CLIENT_ID,
  FB_APP_ID,
  FB_APP_FIELDS,
} from "./containers/App/constants";

// Import i18n messages
import { translationMessages } from "./i18n";

// Create redux store with history
const initialState = {};
const { store, reduxHistory } = configureStore(initialState);
// const MOUNT_NODE = document.getElementById('app');

// const theme = createTheme({
//   typography: {
//     useNextVariants: true,
//   },
//   // palette: {
//   //   primary: 'blue',
//   // },
// });

const tokenWithBearer = localStorage.getItem("token");
if (tokenWithBearer) {
  const token = tokenWithBearer.split(" ")[1];
  // console.log(token,'22222')
  try {
    const decoded = jwtDecode(token);
    if (
      !(
        typeof decoded === "object" &&
        typeof decoded.exp === "number" &&
        decoded.exp > Date.now() / 1000
      )
    ) {
      localStorage.removeItem("token");
    } else {
      const routes = JSON.parse(localStorage.getItem("routes"));
      const user = {
        id: decoded.id,
        name: decoded.name,
        avatar: decoded.avatar,
        email: decoded.email,
        roles: decoded.roles,
        email_verified: decoded.email_verified,
        routes,
      };
      store.dispatch(setToken(tokenWithBearer));
      store.dispatch(setUser(user));
    }
  } catch (error) {
    localStorage.removeItem("token");
  }
}

function App() {
  return (
    <FacebookProvider appId={FB_APP_ID}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <ThemeProvider>
            <Router history={reduxHistory}>
              {/* <ConnectedRouter history={history}> */}
              <SnackbarProvider maxSnack={3} className="mb-8 lg:mb-0">
                <Apps />
              </SnackbarProvider>
              {/* </ConnectedRouter> */}
            </Router>
          </ThemeProvider>
        </Provider>
      </GoogleOAuthProvider>
    </FacebookProvider>
  );
}

export default App;
