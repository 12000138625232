import { produce } from "immer";

import * as types from "./constants";
import { LOAD_ALL_SUCCESS as MEDIA_LOAD_ALL_SUCCESS } from "../Admin/Media/constants";
import * as utils from "./utils";

// The initial state of the App
export const initialState = {
  user: { isAdmin: false, isSeller: false },
  token: "",
  content: {},
  media: {},
  access: {},
  slide: {},
  notifications: [],
  access: {},
  menu: {},
  cart_id: "",
  guest_token: "",
  contentLoading: false,
  category: [],
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action = { type: "" }) =>
  produce(state, (draft) => {
    const access = {};
    let isAdmin = false;
    let isSeller = false;
    switch (action.type) {
      case MEDIA_LOAD_ALL_SUCCESS:
        draft.media = {
          ...state.media,
          ...utils.normalizeMedia(action.payload.data),
        };
        break;
      case types.SET_USER:
        localStorage.setItem(
          "routes",
          action.payload.routes
            ? JSON.stringify(action.payload.routes)
            : localStorage.routes
        );
        (action.payload.routes || []).map((each) => {
          if (each.admin_routes.includes("/admin/dashboard/cms")) {
            isAdmin = true;
          }
          each.admin_routes.map((route) => {
            access[route] = [...(access[route] || []), each.access_type];
          });
        });
        (action.payload.routes || []).map((each) => {
          if (each.admin_routes.includes("/seller/dashboard")) {
            isSeller = true;
          }
          each.admin_routes.map((route) => {
            access[route] = [...(access[route] || []), each.access_type];
          });
        });
        draft.user = {
          ...action.payload,
          isAdmin,
          isSeller,
        };
        draft.access = access;
        break;
      case types.SET_TOKEN:
        localStorage.setItem("token", action.payload);
        draft.token = action.payload;
        break;
      case types.LOGOUT:
      case types.LOGOUT_SUCCESS:
        localStorage.setItem("token", "");
        localStorage.setItem("routes", "");
        localStorage.setItem("guest_token", "");
        localStorage.setItem("cart_id", "");

        draft.user = {};
        draft.token = "";
        break;
      case types.ENQUEUE_SNACKBAR:
        draft.notifications = [...draft.notifications, { ...action.payload }];
        break;
      case types.REMOVE_SNACKBAR:
        draft.notifications = [
          ...draft.notifications.filter(
            (notification) => notification.key !== action.payload
          ),
        ];
        break;
      case types.LOAD_MENU_SUCCESS:
        draft.menu = {
          ...draft.menu,
          [action.payload.data.key]: action.payload.data.child,
        };
        break;
      case types.LOAD_CATEGORY_SUCCESS:
        draft.category = action.payload.data;
        break;
      case types.SET_CART_ID:
        localStorage.setItem("cart_id", action.payload);
        draft.cart_id = action.payload;
        break;
      case types.SET_GUEST_TOKEN:
        localStorage.setItem("guest_token", action.payload);
        draft.guest_token = action.payload;
        break;
      case types.ENQUEUE_SNACKBAR:
        draft.notifications = [...draft.notifications, { ...action.payload }];
        break;
      case types.REMOVE_SNACKBAR:
        draft.notifications = [
          ...draft.notifications.filter(
            (notification) => notification.key !== action.payload
          ),
        ];
        break;
      case types.LOAD_MEDIA_SUCCESS:
        draft.media = {
          ...draft.media,
          [action.payload.data._id]: action.payload.data, // eslint-disable-line no-underscore-dangle
        };
        break;
      case types.LOAD_SLIDE_REQUEST:
        draft.loading = true;
        break;
      case types.LOAD_SLIDE_SUCCESS:
        draft.slide = {
          ...draft.slide,
          [action.payload.data.slider_key]: action.payload.data,
        };
        draft.loading = false;
        break;
      case types.LOAD_SLIDE_FAILURE:
        draft.loading = false;
        break;
      case types.LOAD_CONTENT_REQUEST:
        draft.contentLoading = true;
        break;
      case types.LOAD_CONTENT_FAILURE:
        draft.contentLoading = false;
        break;
      case types.LOAD_CONTENT_SUCCESS:
        draft.content = {
          ...draft.content,
          [action.payload.data.key]: action.payload.data.description,
        };
        draft.contentLoading = false;
        break;
    }
  });

export default appReducer;
