import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectRouter = (state) => state.router;

export const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) => routerState.location);

const selectGlobal = (state) => state.global || initialState;

export const makeSelectIsAuthenticated = () =>
  createSelector(selectGlobal, (state) => !!state.token);

export const makeSelectToken = () =>
  createSelector(selectGlobal, (state) => state.token);

export const makeSelectUser = () =>
  createSelector(selectGlobal, (state) => state.user);

export const makeSelectUserIsAdmin = () =>
  createSelector(makeSelectUser(), (state) => state.isAdmin);

export const makeSelectSlide = () =>
  createSelector(selectGlobal, (state) => state.slide);
export const makeSelectUserIsSeller = () =>
  createSelector(makeSelectUser(), (state) => state.isSeller);

export const makeSelectRoles = () =>
  createSelector(makeSelectUser(), (state) => state.roles);

export const makeSelectNotifications = () =>
  createSelector(selectGlobal, (state) => state.notifications);

export const makeSelectAccess = () =>
  createSelector(selectGlobal, (state) => state.access);

export const makeSelectCategory = () =>
  createSelector(selectGlobal, (state) => state.category);

export const makeSelectMenu = () =>
  createSelector(selectGlobal, (state) => state.menu);

export const makeSelectCartId = () =>
  createSelector(selectGlobal, (state) => state.cart_id);

export const makeSelectGuestToken = () =>
  createSelector(selectGlobal, (state) => state.guest_token);
export const makeSelectContentLoading = () =>
  createSelector(selectGlobal, (state) => state.contentLoading);

export const makeSelectContent = () =>
  createSelector(selectGlobal, (state) => state.content);

export const makeSelectLoading = () =>
  createSelector(selectGlobal, (state) => state.loading);
