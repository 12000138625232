/*
 *
 * ViewCart actions
 *
 */

import * as types from "./constants";

export const loadCartRequest = (payload) => ({
  type: types.LOAD_CART_REQUEST,
  payload,
});
export const loadCartSuccess = (payload) => ({
  type: types.LOAD_CART_SUCCESS,
  payload,
});
export const loadCartFailure = (payload) => ({
  type: types.LOAD_CART_FAILURE,
  payload,
});

export const deleteFromCartRequest = (payload) => ({
  type: types.DELETE_FROM_CART_REQUEST,
  payload,
});
export const deleteFromCartSuccess = (payload) => ({
  type: types.DELETE_FROM_CART_SUCCESS,
  payload,
});
export const deleteFromCartFailure = (payload) => ({
  type: types.DELETE_FROM_CART_FAILURE,
  payload,
});

export const addToCartRequest = (payload) => ({
  type: types.ADD_TO_CART_REQUEST,
  payload,
});
export const addToCartSuccess = (payload) => ({
  type: types.ADD_TO_CART_SUCCESS,
  payload,
});
export const addToCartFailure = (payload) => ({
  type: types.ADD_TO_CART_FAILURE,
  payload,
});

export const clearCartData = (payload) => ({
  type: types.CLEAR_CART_DATA,
  payload,
});

export const clearError = (payload) => ({
  type: types.CLEAR_ERROR,
  payload,
});

export const setCartData = (payload) => ({
  type: types.SET_CART_DATA,
  payload,
});

export const setCoupon = (payload) => ({
  type: types.SET_COUPON,
  payload,
});

export const applyForCouponRequest = (payload) => ({
  type: types.APPLY_FOR_COUPON_REQUEST,
  payload,
});
export const applyForCouponSuccess = (payload) => ({
  type: types.APPLY_FOR_COUPON_SUCCESS,
  payload,
});
export const applyForCouponFailure = (payload) => ({
  type: types.APPLY_FOR_COUPON_FAILURE,
  payload,
});

export const addToFavoriteRequest = (payload) => ({
  type: types.ADD_TO_FAVORITE_REQUEST,
  payload,
});
export const addToFavoriteSuccess = (payload) => ({
  type: types.ADD_TO_FAVORITE_SUCCESS,
  payload,
});
export const addToFavoriteFailure = (payload) => ({
  type: types.ADD_TO_FAVORITE_FAILURE,
  payload,
});

export const setButtonDisabled = (payload) => ({
  type: types.SET_BUTTON_DISABLED,
  payload,
});

export const buyNowRequest = (payload) => ({
  type: types.BUY_NOW_REQUEST,
  payload,
});
export const buyNowSuccess = (payload) => ({
  type: types.BUY_NOW_SUCCESS,
  payload,
});
export const buyNowFailure = (payload) => ({
  type: types.BUY_NOW_FAILURE,
  payload,
});

export const setShowList = (payload) => ({
  type: types.SET_SHOW_LIST,
  payload,
});
export const setShippingPrice = (payload) => ({
  type: types.SET_SHIPPING_PRICE,
  payload,
});
