export const API_BASE =
  //   process.env.NODE_ENV === 'production'
  //     ? 'https://gyapu.wafttech.com/api/' :
  // "http://localhost:5300/api/";
  "http://3.110.223.56:5300/api/";

export const IMAGE_BASE =
  //   process.env.NODE_ENV === 'production'
  //     ? 'https://gyapu.wafttech.com/' :
  // "http://localhost:5300/";
  "http://3.110.223.56:5300/";

export const FB_APP_ID = "2042041299586640";
// export const FB_APP_FIELDS = 'id,email,name';
// export const RECAPTCHA_SITE_KEY = '6LePKOIUAAAAAE5dZI2-jpQP5lyftAT2CSAW5U54';
export const GOOGLE_CLIENT_ID =
  // "584546370460-afjb8r9u7h60sduibeooa9ifnlciiolp.apps.googleusercontent.com";
  "584546370460-aan797dsp6c1kftnrca0bovetr1tmh4l.apps.googleusercontent.com";

export const URL_BASE = "http://3.110.223.56:5300";
// export const URL_BASE = "http://localhost:5300";

// process.env.NODE_ENV === 'production'
//   ? 'https://gyapu.icoder.com'
//   : 'http://localhost:5211';

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const MIN_CART_AMOUNT = 10;
export const DATE_FORMAT = "ll";
// export const FB_APP_ID = "2462702277346340";
export const FB_APP_FIELDS = "id,email,name";

export const SET_USER = "app/App/SET_USER";

export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "LOGOUT";
// export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGOUT_REQUEST = "app/App/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "app/App/LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "app/App/LOGOUT_FAILURE";

export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const NETWORK_ERROR = "NETWORK_ERROR";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const LOAD_SLIDE_REQUEST = "app/App/LOAD_SLIDE_REQUEST";
export const LOAD_SLIDE_SUCCESS = "app/App/LOAD_SLIDE_SUCCESS";
export const LOAD_SLIDE_FAILURE = "app/App/LOAD_SLIDE_FAILURE";

export const LOAD_MENU_REQUEST = "app/App/LOAD_MENU_REQUEST";
export const LOAD_MENU_SUCCESS = "app/App/LOAD_MENU_SUCCESS";
export const LOAD_MENU_FAILURE = "app/App/LOAD_MENU_FAILURE";

export const SET_CART_ID = "app/App/SET_CART_ID";
export const SET_GUEST_TOKEN = "app/App/SET_GUEST_TOKEN";

export const LOAD_CONTENT_REQUEST = "app/App/LOAD_CONTENT_REQUEST";
export const LOAD_CONTENT_SUCCESS = "app/App/LOAD_CONTENT_SUCCESS";
export const LOAD_CONTENT_FAILURE = "app/App/LOAD_CONTENT_FAILURE";

export const LOAD_MEDIA_REQUEST = "app/App/LOAD_MEDIA_REQUEST";
export const LOAD_MEDIA_SUCCESS = "app/App/LOAD_MEDIA_SUCCESS";
export const LOAD_MEDIA_FAILURE = "app/App/LOAD_MEDIA_FAILURE";

export const LOAD_CATEGORY_REQUEST =
  "app/CategoryListingPage/LOAD_CATEGORY_REQUEST";
export const LOAD_CATEGORY_SUCCESS =
  "app/CategoryListingPage/LOAD_CATEGORY_SUCCESS";
export const LOAD_CATEGORY_FAILURE =
  "app/CategoryListingPage/LOAD_CATEGORY_FAILURE";
